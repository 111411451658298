import React from "react"
import { graphql } from "gatsby"
import Pagination from "../components/ui/Pagination"
import Layout from "../components/layout/Layout"
import { Helmet } from "react-helmet"
import Block from "../components/ui/Block"
import ArticlesList from "../components/ui/ArticlesList"

export default ({ pageContext, data }) => {
  const { numPages, currentPage } = pageContext
  const { nodes, totalCount } = data.allMdx
  const title = `${totalCount} article${
    totalCount === 1 ? "" : "s"
  } répertorié${totalCount === 1 ? "" : "s"}`

  return (
    <Layout wide>
      <Block wide>
        <Helmet title={title} />
        <h1>{title}</h1>

        <ArticlesList articles={nodes} />

        <Pagination
          current={currentPage}
          total={numPages}
          linkBase={`/all`}
          linkFirstPage={"/"}
        />
      </Block>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      nodes {
        id
        frontmatter {
          title
          date(formatString: "D MMMM YYYY", locale: "fr-FR")
          dateEnd(formatString: "D MMMM YYYY", locale: "fr-FR")
          place
          country
          mainPicture {
            base
            childImageSharp {
              fluid(maxWidth: 400, srcSetBreakpoints: [400]) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fields {
          slug
          isPublished
          isPublic
        }
        excerpt
      }
    }
  }
`
